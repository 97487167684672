import React from 'react';
import Link from 'gatsby-link';

class Menu extends React.Component {

  state = { showMenu: false, pass: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  componentDidMount() {
    this.checkParam()
  }

  checkParam() {
    const secret = "Conference2021";
    if (new URLSearchParams(window.location.search).get('p') === secret) {
      this.setState({
        pass: true
      })
    }
  }

  render() {
    const secret = "Conference2021";
    const menuActive = this.state.showMenu ? "open" : '';
    const param = this.state.pass ? `?p=${secret}` : "";

      return (
        <div className={`menu ${menuActive}`}>
          <button type="button" onClick={this.toggleMenu}>Menu</button>
          <div className="list">
            <Link to={`/${param}`}>Enter</Link>
            <Link to={`/welcome${param}`}>Welcome</Link>
            <Link to={`/programme${param}`}>Programme</Link>
            <Link to={`/speakers${param}`}>Speakers</Link>
            <Link to={`/posters${param}`}>Posters</Link>
            <Link to={`/exhibition${param}`}>Exhibition</Link>
          </div>
        </div>
      )
  }
}

export default Menu
